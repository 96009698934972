export const CONTENT_TYPES = {
  FUND: 'funds',
  PAGE: 'page',
  NEWS: 'news',
  PRODUCT: 'products',
  DOCUMENTS: 'documents',
  CAREERS: 'careers',
  WEBINARS: 'webinars',
  INHERIT: 'inherit',
  TRANSPARENT: 'transparent',
  CONTENT_CARD: 'Content Card',
  PRODUCT_CARD: 'Product Card',
  FUND_CARD: 'Fund Card',
  PRIMARY_CARD: 'Showcase Card',
  INFO_CARD: 'Info Card',
  CHILDREN: 'Children',
  ACCORDION: 'Accordion',
  TAB: 'Tab',
  FLOATING_TABS: 'Floating Tabs',
  STAGE_SELECTOR: 'Stage Selector',
  CONTENT_MENU: 'ContentfulMenu',
  CONTENT_LINK: 'ContentfulLinks',
  CONTENT_PAGE: 'ContentfulPage',
  CONTENT_NEWS: 'ContentfulNews',
  CONTENT_PEOPLE: 'ContentfulPeople',
  CONTENT_PRODUCT: 'ContentfulProduct',
  CONTENT_FUND: 'ContentfulFunds',
  COMPONENT: 'ContentfulLayoutComponent',
  COMPONENT_TABLE: 'TableComponent',
  GENERAL_CARD: 'ContentfulContentGeneric',
  NEWS_BASE: 'press',
  WEBINAR_BASE: 'webinar',
  CAREERS_BASE: 'career',
  FAQS_BASE: 'faq',
  FUND_BASE: 'fund',
  PRODUCT_BASE: 'product',
  TESTIMONIAL: 'Testimonial',
  CONTENT: 'ContentfulContent',
  TEXT: 'StyledText',
  BIO: 'Bio',
  IFRAME: 'ContentfulLayoutIFrame',
};

export const TEMPLATE_TYPES = {
  HOME: 'home',
  PAGE: 'page',
  FUND: 'funds',
  NEWS: 'news',
  CAREERS: 'careers',
};

export const REFINEMENT_TYPES = {
  BUTTONGROUP: 'ButtonGroup',
  DROPDOWN: 'Dropdown',
};

export const VIEW_TYPES = {
  LIST: 'List',
  CARDS: 'Cards',
  PERFORMANCE: 'Performance',
};

export const CONTENT_MODELS_BY_TYPE = {
  [CONTENT_TYPES.PAGE]: CONTENT_TYPES.CONTENT_PAGE,
  [CONTENT_TYPES.FUND]: CONTENT_TYPES.CONTENT_FUND,
  [CONTENT_TYPES.PRODUCT]: CONTENT_TYPES.CONTENT_PRODUCT,
  [CONTENT_TYPES.NEWS]: CONTENT_TYPES.CONTENT_NEWS,
};

export const CONTENT_BASE_BY_TYPE = {
  [CONTENT_TYPES.CONTENT_FUND]: CONTENT_TYPES.FUND_BASE,
  [CONTENT_TYPES.CONTENT_PRODUCT]: CONTENT_TYPES.PRODUCT_BASE,
  [CONTENT_TYPES.CONTENT_NEWS]: CONTENT_TYPES.NEWS_BASE,
};

export const COLOR_SCHEMES = {
  [CONTENT_TYPES.PAGE]: 'green',
  [CONTENT_TYPES.FUND]: 'purple',
  [CONTENT_TYPES.NEWS]: 'green',
  [CONTENT_TYPES.CAREERS]: 'green',
};

export const SUMMARY_DEFAULTS = {
  TITLE: 'Sygnia',
  DESCRIPTION: 'Sygnia website',
  IMAGE:
    'https://images.ctfassets.net/26bnfuc012vy/4iVRUdZjIotPuEg8cTnI2P/ea22e25f26d8f0b62c554cbccd830009/og_image.jpg',
  URL: 'https://sygnia.co.za',
};

export const CONTENT_TITLES = {
  RELATED_PRODUCTS: 'Invest in these products',
  RELATED_PRODUCTS_DESCRITION: 'These products are compatible with',

  NEWS: 'News & Insights',
  RELATED_NEWS: 'Related News & Insights',
  LATEST_NEWS: 'Latest News & Insights',
  LATEST_ANNOUNCEMENTS: 'Latest Announcements',
  WEBINARS: 'Webinars',
  CAREERS: 'Careers at Sygnia',
  RELATED_CAREERS: 'Related Careers',
  LATEST_CAREERS: 'Latest Careers',

  //Downloads (Request for sentence case: https://app.asana.com/0/1139205392581388/1151906235335411/f)
  factSheet: 'Fact sheet',
  marketingSheet: 'Marketing sheet',
  applicationPack: 'Application pack',
  document: 'Download document',

  //Links:
  openAccount: 'Open an account',
  logIn: 'Log in',

  readMore: 'Read more',
  moreInfo: 'More info',

  Daily: 'NAV PERFORMANCE',
  Constituents: 'FUND CONSTITUENTS',

  // silos

  me: 'for-me',
  children: 'for-my-children',
  business: 'for-my-business',
  institutional: 'for-institutions',
  financialAdvisor: 'for-financial-advisors',
  shareholder: 'for-shareholders',

  //Refinements
  fundType: ['Fund Type', 'Fund Types'],
  productType: ['Product Type', 'Product Types'],
  geographicClass: ['Geographic Class', 'Geographic Classes'],
  assetType: ['Asset Type', 'Asset Types'],
  assetCategory: ['Asset Category', 'Asset Categories'],
  category: ['Category', 'Categories'],
  date: ['Date', 'Dates'],
  publishedDate: ['Published Date', 'Published Dates'],
  tags: ['Category', 'Categories'],
  year: ['Year', 'Years'],
  department: ['Department', 'Departments'],
  location: ['Location', 'Locations'],
  author: ['Author', 'Authors'],
  webinarCategory: ['Category', 'Categories'],
  noResults: 'No results found',
  filterBy: 'Filter By',
  viewAs: 'View As',
  twoPotEstimatedTax: 'Estimated tax amount:',
  twoPotRevenue: 'Amount You Receive:',
  performance: 'Performance',
  netWorth: 'Your current net worth:',
  retirementGoal: 'Your goal amount is:',
  retirementCurrent: "At retirement you'll have:",
  yearToAccumulate: 'Years to accumulate:',
  initialLumpSum: 'Initial lump sum:',
  monthlyContributions: 'Monthly contributions:',
  estimateTax: 'Estimated before tax amount:',
  shortfall: 'Shortfall:',
  disclaimer:
    'We use this information to create your account and email you a copy of your plan. We will NOT share your details with anyone else, spam you or send you “special” offers. Promise.',
  newsletter:
    ' Get first access, curated notes, fund updates, industry news, sales and events',

  //FundInfoWidget
  fundOverview: 'Fund overview',
  classLaunchDate: 'Class launch date',
  launchDate: 'Fund launch date',
  riskProfile: 'Risk profile',
  timeHorizon: 'Time horizon',
  fundSize: 'Fund size',
  regulation28: 'Regulation 28',
  benchmark: 'Benchmark',
  portfolioManagers: 'Portfolio managers',

  //Robo
  riskProfileRobo: 'Risk profile is:',
  changeAnswers: 'Change my answers',
  assetMatrix: 'Your asset matrix:',
  unitTrust: 'Unit trust',
  assetClass: 'Asset class',
  historicalPerformance: 'Historical performance',
};

export const ROBOSTEPS = {
  INTRO: 'intro',
  PLAN: 'plan',
  RESULTS: 'results',
  GOAL_RESULTS: 'goalResults',
  FORM: 'form',
  APPLY: 'apply',
};

export const SILO_SLUGS = {
  // silos

  me: 'for-me',
  children: 'for-my-children',
  business: 'for-my-business',
  institutional: 'for-institutions',
  financialAdvisor: 'for-financial-advisors',
  shareholder: 'for-shareholders',
};

export const STATIC_LINKS = {
  termsLink: `I accept the&nbsp;<a target="_blank" href="//assets.ctfassets.net/26bnfuc012vy/3126IjNrEFpqNYPVSCi9p1/4ae6cc7842b0f9afe29261036bc62b6f/Web_Legal_Condition_2024.pdf" to="//assets.ctfassets.net/26bnfuc012vy/3126IjNrEFpqNYPVSCi9p1/4ae6cc7842b0f9afe29261036bc62b6f/Web_Legal_Condition_2024.pdf">terms &amp; conditions</a>`,
  termsLinkV2: `I accept the&nbsp;<a target="_blank" href="//assets.ctfassets.net/26bnfuc012vy/6mGrZ59vPAFhhphVRbMbDg/72bf15e4f31b1c7275b2983a45c6ae0c/Terms_and_Conditions__Retail_and_SURF_.pdf" to="http://assets.ctfassets.net/26bnfuc012vy/6mGrZ59vPAFhhphVRbMbDg/72bf15e4f31b1c7275b2983a45c6ae0c/Terms_and_Conditions__Retail_and_SURF_.pdf">terms &amp; conditions</a>`,
  riskProfileURL: '/risk-profile',
  newsletterLink: `By submitting this form, you consent to us processing your personal information to send you either electronic and/or telephonic communication. If you would like to know more, please see our &nbsp;<a target="_blank" href="//assets.ctfassets.net/26bnfuc012vy/2cFbxqKB0jxSfJeIaNxsC/d64655420cf0f81e54e256337a4928fc/Sygnia-Web-PrivacyPolicy.pdf">Privacy Policy</a>.`,
};

export const DOWNLOAD_AGREEMENT_TEXT = {
  text:
    'By clicking on and downloading the Fund Constituents data, you agree not to redistribute, commercialise and / or create any derivative works from such data.',
};

export const GTM_EVENT = {
  EMAIL_CLICKED: 'EMAIL_CLICKED',
  OPEN_AN_ACCOUNT_CLICKED: 'OPEN_AN_ACCOUNT_CLICKED',
  SIGN_ME_UP_CLICKED: 'SIGN_ME_UP_CLICKED',
  REQUEST_A_QUOTE_CLICKED: 'REQUEST_A_QUOTE_CLICKED',
  ROBO_ADVISOR_STARTED: 'ROBO_ADVISOR_STARTED',
  MORE_INFO_CLICKED: 'MORE_INFO_CLICKED',
  FACT_SHEET_DOWNLOADED: 'FACTSHEET_DOWNLOADED',
  MARKETING_SHEET_DOWNLOADED: 'MARKETINGSHEET_DOWNLOADED',
  BROCHURE_DOWNLOADED: 'BROCHURE_DOWNLOADED',
  APPLICATION_PACK_DOWNLOADED: 'APPLICATION_PACK_DOWNLOADED',
  SOCIAL_CLICKED: 'SOCIAL_CLICKED',
  TEL_CLICKED: 'TEL_CLICKED',
};

export const GTM_CATEGORY = {
  FORM: 'FORM',
  DOCUMENT: 'DOCUMENT',
  CONTACT: 'CONTACT',
  LEAD: 'LEAD',
  SOCIAL: 'SOCIAL',
};

export const GTM_LOCATION = {
  CONTENT: 'CONTENT',
  HERO: 'HERO',
  FOOTER: 'FOOTER',
};
